@import "src/styles/variable";
@import "src/styles/common";

.bill-split {
  margin: 6% 12% 0;
}

.ant-form-item {
  margin-bottom: 26px;

  &:last-child {
    margin-top: 108px;
  }
}

.ant-form-item-label > label {
  font-size: 16px;
}

.bill-split-btn {
  display: flex;
  justify-content: center;
}

.ant-input::placeholder {
  color: $slight-gray;
}

.ant-picker-input > input::placeholder {
  color: $slight-gray;
  text-align: center;
}

.ant-form-horizontal .ant-form-item-label {
  min-width: fit-content;
  margin-top: 5px;
}

.split-line {
  height: 14px;
}

.u-info-sheet-url-error:last-of-type {
  margin: 0;
}

.modify-link-wrapper {
  margin-top: 1em;
}
