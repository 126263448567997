@import "src/styles/variable";

.no-permission-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.no-permission-hint {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.no-permission-hint > span {
  color: $gray;
  text-align: center;
}

.no-permission-hint > span:first-child {
  font-size: 28px;
}

.no-permission-hint > span:last-child {
  color: $black;
  margin-top: 8px;
}

.no-permission-hint > span .hr-email {
  color: $wave-flamingo;
}

.no-permission-image {
  width: 60%;
  height: 50%;
  object-fit: contain;
}
